import React, { useState, useEffect, useRef } from 'react';

const Grid = () => {
  const [rows, setRows] = useState(10);
  const [columns, setColumns] = useState(10);
  const [currentRow, setCurrentRow] = useState(0);
  const [currentColumn, setCurrentColumn] = useState(0);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [gridData, setGridData] = useState([]);
  const inputRefs = useRef([]);
  const [fillDirection, setFillDirection] = useState('lr'); // Filling direction: 'lr' for left-to-right, 'tb' for top-to-bottom

  useEffect(() => {
    const initialGridData = Array(rows)
      .fill('')
      .map(() => Array(columns).fill(''));
    setGridData(initialGridData);

    inputRefs.current = Array(rows).fill(null).map(() => Array(columns).fill(null));
  }, [rows, columns]);

  useEffect(() => {
    if (inputRefs.current[currentRow]?.[currentColumn]) {
      inputRefs.current[currentRow][currentColumn].focus();
    }
  }, [currentRow, currentColumn]);

  const updateHighlighting = () => {
    if (fillDirection === 'lr') {
      setHighlightedRow(currentRow);
      setHighlightedColumn(null);
    } else {
      setHighlightedRow(null);
      setHighlightedColumn(currentColumn);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
    event.preventDefault();
    setFillDirection((prevDirection) => (prevDirection === 'lr' ? 'tb' : 'lr'));
    updateHighlighting();
    if (fillDirection === 'lr') {
      setHighlightedRow(currentRow);
      setHighlightedColumn(null);
    } else {
      setHighlightedRow(null);
      setHighlightedColumn(currentColumn);
    }
    } else if (event.key === 'ArrowUp' && currentRow > 0) {
      setCurrentRow(currentRow - 1);
      setHighlightedRow(fillDirection === 'tb' ? currentRow - 1 : null);
      setHighlightedColumn(fillDirection === 'lr' ? currentColumn : null);
    } else if (event.key === 'ArrowDown' && currentRow < rows - 1) {
      setCurrentRow(currentRow + 1);
      setHighlightedRow(fillDirection === 'tb' ? currentRow + 1 : null);
      setHighlightedColumn(fillDirection === 'lr' ? currentColumn : null);
    } else if (event.key === 'ArrowLeft' && currentColumn > 0) {
      setCurrentColumn(currentColumn - 1);
      setHighlightedRow(fillDirection === 'tb' ? currentRow : null);
      setHighlightedColumn(fillDirection === 'lr' ? currentColumn - 1 : null);
    } else if (event.key === 'ArrowRight' && currentColumn < columns - 1) {
      setCurrentColumn(currentColumn + 1);
      setHighlightedRow(fillDirection === 'tb' ? currentRow : null);
      setHighlightedColumn(fillDirection === 'lr' ? currentColumn + 1 : null);
    }
  };
  
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentRow, currentColumn, rows, columns, fillDirection]);
  
  useEffect(() => {
    updateHighlighting();
  }, [fillDirection]);
  

  const handleSquareClick = (row, column) => {
    setCurrentRow(row);
    setCurrentColumn(column);
  
    if (fillDirection === 'lr') {
      setHighlightedRow(row);
      setHighlightedColumn(null);
    } else {
      setHighlightedRow(null);
      setHighlightedColumn(column);
    }
  };

  const handleInputChange = (event, row, column) => {
    const { value } = event.target;
    const letter = value.slice(-1);
  
    if (/^[a-zA-Z.]$/.test(letter)) {
      const capitalizedLetter = letter.toUpperCase();
      const updatedGridData = [...gridData];
      const squareElement = inputRefs.current[row][column];
  
      if (capitalizedLetter === '.') {
        squareElement.style.backgroundColor = 'black';
      } else {
        updatedGridData[row][column] = capitalizedLetter;
        squareElement.style.backgroundColor = 'white';
      }
  
      setGridData(updatedGridData);
      console.log('Grid Data:', updatedGridData);
  
      if (fillDirection === 'lr') {
        if (column < columns - 1) {
          setCurrentColumn(column + 1);
        } else if (row < rows - 1) {
          setCurrentRow(row + 1);
          setCurrentColumn(0);
        } else {
          // If we are at the last column and the last row, switch to the opposite direction
          setFillDirection('tb');
          setCurrentColumn(column);
          setHighlightedRow(null);
          setHighlightedColumn(column); // Highlight the column
        }
        setHighlightedRow(row); // Highlight the row in both lr and tb modes
        setHighlightedColumn(null);
      } else {
        if (row < rows - 1) {
          setCurrentRow(row + 1);
        } else if (column < columns - 1) {
          setCurrentRow(0);
          setCurrentColumn(column + 1);
        } else {
          // If we are at the last row and the last column, switch to the opposite direction
          setFillDirection('lr');
          setCurrentRow(row);
          setHighlightedRow(row); // Highlight the row
          setHighlightedColumn(null);
        }
        setHighlightedColumn(column); // Highlight the column in both lr and tb modes
        setHighlightedRow(null);
      }
    }
    updateHighlighting();
  };

  const handleBackspace = (event) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
      if (fillDirection === 'lr') {
        if (currentColumn > 0) {
          setCurrentColumn(currentColumn - 1);
        } else if (currentRow > 0) {
          setCurrentRow(currentRow - 1);
          setCurrentColumn(columns - 1);
        } else {
          // If at the first cell, switch to the opposite direction
          setFillDirection('tb');
          setCurrentRow(rows - 1);
          setCurrentColumn(currentColumn);
          setHighlightedRow(rows - 1); // Highlight the row
          setHighlightedColumn(null);
        }
      } else {
        if (currentRow > 0) {
          setCurrentRow(currentRow - 1);
        } else if (currentColumn > 0) {
          setCurrentRow(rows - 1);
          setCurrentColumn(currentColumn - 1);
        } else {
          // Ifmat the first cell, switch to the opposite direction
          setFillDirection('lr');
          setCurrentColumn(columns - 1);
          setCurrentRow(currentRow);
          setHighlightedRow(null);
          setHighlightedColumn(columns - 1); // Highlight the column
        }
      }

      const updatedGridData = [...gridData];
      const squareElement = inputRefs.current[currentRow][currentColumn];

      updatedGridData[currentRow][currentColumn] = '';
      squareElement.value = '';

      setGridData(updatedGridData);
      squareElement.style.backgroundColor = 'white';
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keydown', handleBackspace);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keydown', handleBackspace);
    };
  }, [currentRow, currentColumn, rows, columns]);
  
  const handleInputRef = (row, column, ref) => {
    inputRefs.current[row][column] = ref;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {gridData.map((row, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: 'flex',
            backgroundColor: highlightedRow === rowIndex ? '#ffffcc' : 'transparent',
          }}
        >
          {row.map((square, columnIndex) => (
            <input
            key={columnIndex}
            type="text"
            value={square}
            onChange={(event) => handleInputChange(event, rowIndex, columnIndex)}
            onClick={() => handleSquareClick(rowIndex, columnIndex)}
            readOnly={rowIndex !== currentRow || columnIndex !== currentColumn}
            style={{
              width: '1.8rem',
              height: '1.8rem',
              textAlign: 'center',
              fontSize: '1rem',
              border: '1px solid #ccc',
              borderColor:
                highlightedColumn === columnIndex || highlightedRow === rowIndex
                  ? 'black' // Set the outline color to black for the highlighted square
                  : '#ccc', // Set the outline color to gray for other squares
              backgroundColor: square === 'black' ? 'black' : 'white',
              color: square === 'black' ? 'white' : 'black',
            }}
            ref={(ref) => handleInputRef(rowIndex, columnIndex, ref)}
          />          
          ))}
        </div>
      ))}
    </div>
  );
};

export default Grid;
